body {
  font-family: "Hind Madurai", sans-serif;
  /* font-weight: 400; */
  /* font-style: normal; */
}

.display-text {
  font-family: "Palanquin Dark", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.navbar-text {
  /* font-family: "Hind Madurai", sans-serif;
  font-weight: 300;
  font-style: normal; */
  letter-spacing: 0.05em;
}

.navbar .container-fluid {
  padding-left: calc(var(--bs-gutter-x) * 5);
  padding-right: calc(var(--bs-gutter-x) * 5);
}

.full-height-row {
  height: 100vh;
}

.right-panel-col {
  background-color: rgb(3, 189, 236);
}

.left-panel-col {
  padding-left: 9vw;
  padding-right: 9vw;
}

.landing-row-img {
  height: 35vw;
  /* overflow: hidden; */
}

.secondary-row {
  background-color: rgb(29, 29, 31);
}

.index-footer {
  background-color: rgb(29, 29, 31);
}

.footer-logo {
  height: 60px;
  width: 60px;
}